<template>
  <VContainer fill-height white class="single-location--header">
    <VLayout column>
      <VFlex grow>
        <h1 class="header-2 mt-0 mb-2 location--name">{{
          location.nickname || location.title
        }}</h1>
        <div
          :class="['location--info pt-4', { big: ['lg', 'xl'].includes($mq) }]"
        >
          <p v-if="location.status === 'coming_soon'">{{
            location.description
          }}</p>
          <template v-else-if="location.contactinfo">
            <div class="location--address">
              <div v-if="getContactDetail('street_1')">{{
                getContactDetail('street_1')
              }}</div>
              <div v-if="getContactDetail('street_2')">{{
                getContactDetail('street_2')
              }}</div>
              <span>{{
                `${getContactDetail('city')}, ${getContactDetail(
                  'state'
                )} ${getContactDetail('zip')}`
              }}</span>
              <div v-if="getContactDetail('phone_number')">{{
                getContactDetail('phone_number')
              }}</div>
            </div>
            <div class="mt-4 mb-0 location--hours">{{
              location.hours_for_humans
            }}</div>
          </template>
        </div>
      </VFlex>
      <VFlex v-if="location.status !== 'coming_soon'" shrink>
        <BaseButton v-scroll-to="'#menu'" outline class="ml-0"
          >Show the Menu</BaseButton
        >
        <BaseButton
          v-if="getContactDetail('phone_number')"
          outline
          :href="`tel:${formatPhone(getContactDetail('phone_number'))}`"
          class="ml-0"
          >Call Now</BaseButton
        >
        <BaseButton
          v-if="fullAddress"
          :href="
            `https://www.google.com/maps/dir/?api=1&destination=${encodeURI(
              fullAddress
            )}`
          "
          outline
          target="_blank"
          class="ml-0"
          >Directions</BaseButton
        >
        <BaseButtonChowNow class="ml-0">Order Online</BaseButtonChowNow>
      </VFlex>
    </VLayout>
  </VContainer>
</template>

<script>
import getContactDetail from '@utils/get-contact-detail'
import formatPhone from '@utils/format-phone'
import { mapGetters } from 'vuex'

export default {
  name: 'SingleLocationContactInfo',
  props: {
    location: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('site', ['getSiteTitle']),
    fullAddress() {
      let add1 = this.getContactDetail('street_1') || ''
      let add2 = this.getContactDetail('street_2') || ''
      let city = this.getContactDetail('city') || ''
      let state = this.getContactDetail('state') || ''
      let zip = this.getContactDetail('zip') || ''
      return `${this.getSiteTitle} ${add1}, ${add2}, ${city},${state} ${zip}`
    }, // fullAddress
  },
  methods: {
    formatPhone,
    getContactDetail(key) {
      return getContactDetail(this.location, key)
    }, // getContactDetail
  },
}
</script>

<style lang="scss">
@import '@design';

.location--info {
  font-size: 1.2rem;
  font-weight: 300;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.03em;
  &.big {
    font-size: 1.5rem;
    line-height: 1.7;
  }
  .location--hours {
    white-space: pre;
  }
}
</style>
